import React, { useMemo } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	List,
	ListItem,
	ListItemText,
	Typography,
	Chip,
	Alert,
} from '@mui/material';
import moment from 'moment';

const HistoryRewardChanges = ({ open, order, onClose }) => {
	const sortedHistory = useMemo(() => {
		if (!order?.contractsInfo?.mainObj?.rewards?.amazon) {
			return [];
		}

		const amazonRewards = order.contractsInfo.mainObj.rewards.amazon;
		const matchingReward = amazonRewards.find((reward) => {
			const rewardDate = reward.date?.seconds
				? moment(reward.date.seconds * 1000)
				: null;
			const recordDate = order.date ? moment(order.date, 'DD/MM/YYYY') : null;

			return (
				rewardDate &&
				recordDate &&
				rewardDate.isSame(recordDate, 'day') &&
				reward.quantity === order.quantity &&
				reward.value === order.value
			);
		});

		if (!matchingReward?.history) {
			return [
				{
					date: matchingReward?.date,
					quantity: matchingReward?.quantity,
					value: matchingReward?.value,
					original: true,
				},
			];
		}

		const historyArray = Object.values(matchingReward.history);

		return historyArray.sort((a, b) => {
			if (a.original) return 1;
			if (b.original) return -1;
			return moment(b.date?.seconds * 1000).diff(moment(a.date?.seconds * 1000));
		});
	}, [order]);

	if (!open) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '40%',
					maxHeight: '50%',
				},
			}}
		>
			<DialogTitle>Change History</DialogTitle>
			<DialogContent dividers>
				{!sortedHistory.length ? (
					<Alert severity="info">No change history available</Alert>
				) : (
					<List>
						{sortedHistory.map((entry, index) => (
							<ListItem
								key={`${entry.date?.seconds}-${index}`}
								divider={index !== sortedHistory.length - 1}
							>
								<ListItemText
									primary={
										<Typography variant="subtitle1">
											Date: {moment(entry.date?.seconds * 1000).format('DD/MM/YYYY HH:mm')}
											{index === sortedHistory.length - 1 && (
												<Chip
													label="Original"
													color="primary"
													size="small"
													sx={{ ml: 1 }}
												/>
											)}
										</Typography>
									}
									secondary={
										<>
											<Typography variant="body2">
												Number of Cards: {entry.quantity}
											</Typography>
											<Typography variant="body2">Value: ${entry.value}</Typography>
										</>
									}
								/>
							</ListItem>
						))}
					</List>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default HistoryRewardChanges;
