import React, { useState } from 'react';
import {
	useTheme,
	ThemeProvider,
	Box,
	Button,
	Typography,
	Select,
	MenuItem,
} from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
import { optionsTableDefault } from '../RewardCards/utils';
import OrderNotes from '../RewardCards/OrderNotes';
import ShippingDetails from '../RewardCards/ShippingDetails';
import useRewardCards from '../../hooks/useRewardCards';
import LoadCards from '../RewardCards/LoadCards';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import Search from '../RewardCards/SearchRewardCards';
import moment from 'moment';

const RepCardsActive = () => {
	const theme = useTheme();
	const [order, setOrder] = useState();
	const [open, setOpen] = useState(false);
	const [openShipping, setOpenShipping] = useState(false);
	const [openLoadCards, setOpenLoadCards] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const { orderCardsRecords, setFilter } = useRewardCards();

	const repStatusOptions = [
		'Awaiting Confirmation',
		'Confirmed',
		'Pending Contact',
	];

	const handleOpenShipping = (order) => {
		setSelectedOrder(order);
		setOpenShipping(true);
	};

	const handleCloseShipping = () => {
		setSelectedOrder(null);
		setOpenShipping(false);
	};

	const handleOpenLoadCards = (order) => {
		setSelectedOrder(order);
		setOpenLoadCards(true);
	};

	const handleCloseLoadCards = () => {
		setSelectedOrder(null);
		setOpenLoadCards(false);
	};

	const closeModal = () => {
		setOrder();
		setOpen(false);
	};

	const updateRepStatus = async (orderId, newStatus) => {
		try {
			const orderRef = doc(db, 'orderCards', orderId);
			await updateDoc(orderRef, {
				repStatus: newStatus,
			});
		} catch (error) {
			console.error('Error updating rep status:', error);
		}
	};
	const renderStatus = (status, rowData) => {
		if (!rowData) {
			return <Typography color="textPrimary">Unknown status</Typography>;
		}

		let statusColor = 'textPrimary';
		let statusText = '';
		let ActionButton = null;

		switch (status) {
			case undefined:
				statusColor = 'error';
				statusText = `Available ${moment(rowData.orderDate)
					.date(15)
					.format('MM/DD/YYYY')}`;
				break;
			case 'Requested':
				statusColor = 'textPrimary';
				statusText = 'Requested';
				break;
			case 'Ordered':
				statusColor = 'success';
				statusText = 'Ordered';
				break;
			case 'Shipped':
				statusColor = 'textPrimary';
				statusText = 'Shipped';
				ActionButton = (
					<Button
						sx={{ textTransform: 'capitalize' }}
						onClick={() => handleOpenShipping(rowData)}
						color="primary"
						size="small"
					>
						Details
					</Button>
				);
				break;
			case 'Delivered':
				statusColor = 'textPrimary';
				statusText = 'Delivered |';
				ActionButton = (
					<Button
						sx={{ textTransform: 'capitalize' }}
						onClick={() => handleOpenLoadCards(rowData)}
						color="success"
						size="small"
					>
						Load Cards
					</Button>
				);
				break;
			case 'Received':
				statusColor = 'textPrimary';
				statusText = 'Received';
				break;
			case 'Fulfilled':
				statusColor = 'textPrimary';
				statusText = 'Fulfilled';
				break;
			case 'Failed':
				statusColor = 'textPrimary';
				statusText = 'Failed';
				break;
			default:
				statusColor = 'textPrimary';
				statusText = 'Unknown status';
		}

		return (
			<Box display="flex" alignItems="center">
				<Typography color={statusColor}>{statusText}</Typography>
				{ActionButton}
			</Box>
		);
	};

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},

		{
			name: 'mainStation',
			label: 'Main Station',
		},
		{
			name: 'shipment',
			label: 'Shipment # (? of ?)',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return rowData.shipment
						? `${rowData.shipment} of ${rowData.totalShipments}`
						: '1 of 1';
				},
			},
		},
		{
			name: 'shipmentDueDate',
			label: 'Shipment Due',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return moment(rowData?.shipmentDueDate || rowData.orderDate).format(
						'MM/DD/YYYY'
					);
				},
			},
		},
		{
			name: 'denomination',
			label: 'Shipment Detail',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return `${rowData.qty} Cards / $ ${rowData.denomination}`;
				},
			},
		},
		{
			name: 'name',
			label: 'Contact',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return rowData?.name ?? 'N/A';
				},
			},
		},
		{
			name: 'repStatus',
			label: 'Rep Status',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];

					return (
						<Select
							value={rowData?.repStatus || ''}
							onChange={(event) => {
								updateRepStatus(rowData.id, event.target.value);
							}}
							size="small"
							sx={{ minWidth: 150, fontSize: 13 }}
						>
							{repStatusOptions.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					);
				},
			},
		},
		{
			name: 'status',
			label: 'Order Status',
			options: {
				customBodyRender: (value, tableMeta) => {
					const rowData = orderCardsRecords[tableMeta.rowIndex];
					return renderStatus(value, rowData);
				},
			},
		},
	];

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="flex-end">
				<Typography sx={{ width: '100%' }} variant="h6">
					Active Deals
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						width: '100%',
					}}
				>
					<Search setFilter={setFilter} />
				</Box>
			</Box>

			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCardsRecords || []}
						columns={columns}
						options={{
							...optionsTableDefault,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
					<OrderNotes open={open} closeModal={closeModal} order={order} />
					<ShippingDetails
						order={selectedOrder}
						open={openShipping}
						onClose={handleCloseShipping}
					/>
					<LoadCards
						order={selectedOrder}
						open={openLoadCards}
						onClose={handleCloseLoadCards}
					/>
				</ThemeProvider>
			</MainBox>
		</>
	);
};
export default RepCardsActive;
