import moment from 'moment';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

export const optionsTableDefault = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 50,
	rowsPerPageOptions: [25, 50, 100],
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
};

export const optionsOrderHistory = {
	filterType: 'dropdown',
	pagination: true,
	rowsPerPage: 5,
	print: false,
	download: false,
	viewColumns: false,
	search: false,
	filter: false,
	sort: true,
	selectableRows: 'none',
};

export const formatKey = (key) => {
	return key
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (str) => str.toUpperCase());
};

export const createUniqueId = (record, index) => {
	// Generate a unique ID for each record
	return `${record.contractId}-${record.dealNumber}-${index}`;
};

export const handleCheckboxChange = async (
	record,
	isChecked,
	index,
	currentMonth,
	currentYear,
	setCheckedState
) => {
	const { contractsInfo } = record;
	const uniqueId = createUniqueId(record, index);
	try {
		const rewards = contractsInfo.mainObj?.rewards || {};
		const visaRewards = rewards.visa || [];

		if (Array.isArray(visaRewards)) {
			const updatedVisaRewards = visaRewards.map((reward) => {
				const rewardDate =
					reward.date && reward.date.seconds
						? new Date(reward.date.seconds * 1000)
						: null;
				// Check if the reward matches the current month and year
				const isMatch =
					rewardDate &&
					moment(rewardDate).month() === currentMonth &&
					moment(rewardDate).year() === currentYear &&
					reward.quantity === record.quantity &&
					reward.value === record.value;

				if (isMatch) {
					return { ...reward, manually: isChecked }; // Update the reward
				}
				return reward;
			});

			// Update the state with the new checked value
			setCheckedState((prevState) => ({
				...prevState,
				[uniqueId]: isChecked,
			}));

			// Update the rewards in the database
			await updateDoc(doc(db, 'contracts', record.contractId), {
				'mainObj.rewards.visa': updatedVisaRewards,
			});
		} else {
			console.error('The rewards are not an array');
		}
	} catch (error) {
		console.error('Error to update order', error);
	}
};

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const years = ['2024', '2025'];

const isMatchingReward = (reward, record) => {
	// Validate if the record has a date
	const rewardDate =
		reward.date && reward.date.seconds
			? moment(reward.date.seconds * 1000)
			: null;

	const recordDate = record.date ? moment(record.date, 'DD/MM/YYYY') : null;

	// Return true if date, quantity and value match
	return (
		rewardDate &&
		recordDate &&
		rewardDate.isSame(recordDate, 'day') &&
		reward.quantity === record.quantity &&
		reward.value === record.value
	);
};

export const updateRewardHistory = async (record, newValues) => {
	try {
		const { contractsInfo } = record;
		const rewards = contractsInfo.mainObj?.rewards || {};
		const visaRewards = rewards.visa || [];

		const updatedVisaRewards = visaRewards.map((reward) => {
			if (isMatchingReward(reward, record)) {
				const newHistoryEntry = {
					date: new Date(),
					quantity: newValues.quantity,
					value: newValues.value,
				};

				const history = reward.history || [
					{
						date: reward.date,
						quantity: reward.quantity,
						value: reward.value,
						original: true,
					},
				];

				return {
					...reward,
					...newValues,
					history: [...history, newHistoryEntry],
				};
			}
			return reward;
		});
		// Update the rewards in the database
		await updateDoc(doc(db, 'contracts', record.contractId), {
			'mainObj.rewards.visa': updatedVisaRewards,
		});
	} catch (error) {
		console.error('Error updating reward history:', error);
	}
};

export const changeMonthOrder = async (record, selectedDate) => {
	try {
		const { contractsInfo } = record;
		const rewards = contractsInfo.mainObj?.rewards || {};
		const visaRewards = rewards.visa || [];

		const changeVisaRewards = visaRewards.map((reward) => {
			if (isMatchingReward(reward, record)) {
				const timestamp = Timestamp.fromDate(selectedDate);
				const newHistoryEntry = {
					date: new Date(),
					quantity: reward.quantity,
					value: reward.value,
				};

				const history = reward.history || [
					{
						date: reward.date,
						quantity: reward.quantity,
						value: reward.value,
						original: true,
					},
				];
				return {
					...reward,
					date: {
						seconds: timestamp.seconds,
						nanoseconds: timestamp.nanoseconds,
					},
					history: [...history, newHistoryEntry],
				};
			}
			return reward;
		});

		await updateDoc(doc(db, 'contracts', record.contractId), {
			'mainObj.rewards.visa': changeVisaRewards,
		});
	} catch (error) {
		console.error('Error updating reward date:', error);
	}
};
