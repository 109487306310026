import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import {
	useTheme,
	ThemeProvider,
	Typography,
	IconButton,
	Stack,
} from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import useGetAmazonCards from '../../hooks/useGetAmazonCards';
import { optionsTableDefault } from '../RewardCards/utils';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import EditAmazonReward from './EditAmazonReward';
import HistoryAmazonChanges from './HistoryAmazonChanges';

const CurrentRewardCards = () => {
	const currentMonth = moment().month();
	const nextMonth = (currentMonth + 1) % 12; // Will be 0 for January if current is December
	const currentYear =
		currentMonth === 11 ? moment().year() + 1 : moment().year();
	const orderCards = useGetAmazonCards(nextMonth, currentYear);
	const theme = useTheme();
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [openEdit, setOpenEdit] = useState(false);
	const [openHistory, setOpenHistory] = useState(false);

	const handleEdit = (order) => {
		setSelectedOrder(order);
		setOpenEdit(true);
	};

	const handleHistory = (order) => {
		setSelectedOrder(order);
		setOpenHistory(true);
	};

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},
		{
			name: 'date',
			label: 'Date',
		},
		{
			name: 'contractName',
			label: 'Contract Name',
		},
		{
			name: 'balance',
			label: 'Balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'quantity',
			label: 'Number Of Cards',
		},
		{
			name: 'value',
			label: 'Denomination',
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				customBodyRender: (value, tableMeta) => {
					const record = orderCards[tableMeta.rowIndex];
					return (
						<Stack direction="row" spacing={1}>
							<IconButton
								size="small"
								onClick={() => handleEdit(record)}
								color="primary"
							>
								<EditIcon />
							</IconButton>
							<IconButton
								size="small"
								onClick={() => handleHistory(record)}
								color="primary"
							>
								<HistoryIcon />
							</IconButton>
						</Stack>
					);
				},
			},
		},
	];

	return (
		<>
			<Typography variant="h6">
				Orders for {moment().add(1, 'month').format('MMMM')}
			</Typography>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCards || []}
						columns={columns}
						options={{
							...optionsTableDefault,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
				</ThemeProvider>
			</MainBox>
			<EditAmazonReward
				open={openEdit}
				order={selectedOrder}
				onClose={() => setOpenEdit(false)}
			/>
			<HistoryAmazonChanges
				open={openHistory}
				order={selectedOrder}
				onClose={() => setOpenHistory(false)}
			/>
		</>
	);
};
export default CurrentRewardCards;
