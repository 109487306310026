import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@mui/material';

const DownloadReport = ({ data, month, year }) => {
	if (!data || !data.length) return null;
	const headers = Object.keys(data[0] || {}).map((key) => ({
		label: key
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase()),
		key: key,
	}));

	return (
		<CSVLink
			data={data}
			headers={headers}
			filename={`Report-${month}-${year}.csv`}
		>
			<Button variant="contained" color="success">
				Download
			</Button>
		</CSVLink>
	);
};

export default DownloadReport;
