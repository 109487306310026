/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import SearchForm from './components/SearchForm';
import { collection, limit, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../firebase/config';
import DataTable from './components/DataTable';
import { stationRef } from '../../utils';

const SalesProspector = () => {
	const [salesList, setSalesList] = useState([]);
	const [ownerList, setOwnerList] = useState([]);
	const [DMAList, setDMAList] = useState([]);
	const [formatList, setFormatList] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);

	const collRef = collection(db, stationRef);

	useEffect(() => {
		const q = query(collRef, limit(500));
		const unsub = onSnapshot(q, (snapshot) => {
			let ownerList = [];
			let DMAList = [];
			let formatList = [];
			snapshot.docs.forEach((doc) => {
				ownerList.push(doc.data().Ownername);
				DMAList.push(doc.data().NDMAName);
				formatList.push(doc.data().FmtName1);
			});
			ownerList = [...new Set(ownerList)]; // [ 'A', 'B']
			DMAList = [...new Set(DMAList.sort())];
			formatList = [...new Set(formatList.sort())];
			setOwnerList(ownerList);
			setDMAList(DMAList);
			setFormatList(formatList);
		});

		return () => unsub();
	}, []);

	return (
		<Box>
			<Grid container>
				<Grid item xs={12} sm={12}>
					<SearchForm
						refDb={collRef}
						salesList={salesList}
						ownerList={ownerList}
						DMAList={DMAList}
						formatList={formatList}
						setSalesList={setSalesList}
						selectedRows={selectedRows}
					/>
				</Grid>
				<Grid item xs={12} mt={2}>
					<DataTable
						salesList={salesList}
						setSelectedRows={setSelectedRows}
						selectedRows={selectedRows}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SalesProspector;
