import React, { useEffect, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Card, CardContent, Dialog, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditCost from './EditCost';
import { useRoles } from '../../hooks/useRoles';
import { getNotesById } from '../../utils/utils';
import AcceptedItem from './AcceptedItem';
const ItemsDetails = ({ contractId, itemId, statusModal, closeModal }) => {
	const [query, setQuery] = useState();
	const { isBillPayManager } = useRoles();
	const [item] = useDocument(query);
	const [description, setDescription] = useState('');
	const [name, setName] = useState('');
	const [instructions, setInstructions] = useState('');
	const [isEditCost, setEditCost] = useState(false);
	const [itemData, setItem] = useState();
	useEffect(() => {
		if (item && item.exists()) {
			const data = item.data();
			if (data) {
				if (data.item) setName(data.item);
				if (data.description) setDescription(data.description);
				if (data.instructions) setInstructions(data.instructions);
				setItem(data);
			}
		}
	}, [item, isEditCost]);
	useEffect(() => {
		return () => {
			setEditCost(false);
		};
	}, []);
	useEffect(() => {
		if (contractId && itemId) {
			const documentRef = getNotesById(itemId, contractId);
			setQuery(documentRef);
		}
	}, [contractId, itemId]);

	return (
		<Dialog
			open={statusModal}
			onClose={closeModal}
			disableAutoFocus
			maxWidth={'sm'}
			fullWidth={true}
		>
			<DialogTitle> Item Detail</DialogTitle>
			<DialogContent>
				<Card>
					<React.Fragment>
						<CardContent>
							<Typography
								textAlign={'center'}
								variant="h6"
								fontWeight={900}
								component={'div'}
							>
								{name}
							</Typography>
							<EditCost
								isBillPayManager={isBillPayManager}
								item={itemData}
								query={query}
							></EditCost>
							<Stack>
								<Typography variant="h6">Description: </Typography>
								<Typography>{description}</Typography>
							</Stack>

							<Stack>
								<Typography variant="h6">Instructions: </Typography>
								<Typography>{instructions}</Typography>
							</Stack>
						</CardContent>
					</React.Fragment>
				</Card>
			</DialogContent>
			<AcceptedItem
				contractId={contractId}
				isBillPayManager={isBillPayManager}
				item={itemData}
				itemId={itemId}
			></AcceptedItem>
		</Dialog>
	);
};
export default ItemsDetails;
