import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {
	Button,
	TableHead,
	TableRow,
	TableCell,
	Checkbox,
	Box,
	Paper,
	TableContainer,
	Table,
	TableBody,
	Grid,
} from '@mui/material';
import {
	collection,
	documentId,
	query,
	where,
	onSnapshot,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import {
	createCreditsTableRowData,
	filterDataList,
	initialFilterStationAtom,
} from '../../StationList/utils/constants';
import { useAtomValue } from 'jotai';
import {
	updateOrCreateOptionData,
	validateOptionErrors,
	validateVacationCredits,
	proposalStatusReview,
} from '../utils/utils';
import { defaultRowsVacations } from '../utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const withOutairFareCells = [
	{
		id: 'id',
		align: 'center',
		label: '',
	},
	{
		id: 'name',
		align: 'center',
		label: 'Vacations without air fare',
	},
	{
		id: 'credits',
		align: 'right',
		label: 'Credits',
	},
];

const withAirFareCells = [
	{
		id: 'id',
		align: 'center',
		label: '',
	},
	{
		id: 'name',
		align: 'center',
		label: 'Vacations with air fare',
	},
	{
		id: 'credits',
		align: 'right',
		label: 'Credits',
	},
];

const specialtyCells = [
	{
		id: 'id',
		align: 'center',
		label: '',
	},
	{
		id: 'name',
		align: 'center',
		label: 'Specialty Vacations',
	},
	{
		id: 'credits',
		align: 'right',
		label: 'Credits',
	},
];

export default function VacationsModal({
	openModal,
	onClose,
	docId,
	optionId,
	setOptionId,
	setVacationCredits,
	openPublishQuoteModal,
	openOverrideModal,
	proposalState,
	allInputs,
	isAqhOption,
	isEditAqhOption,
	optionData,
	aqhSavedOptions,
	rewards,
	isNewOne,
	setIsNewOne,
	proposalObj,
	setProposalId,
	setAqhSavedOptions,
	setOpenVacationTripsModal,
	setOpenRewardsModal,
	isPublish,
	setOpenAirportsModal,
	dealTerms,
	setOpenRolloverModal,
	setCurrentVacationCredits,
}) {
	const [docData, setDocData] = useState({});
	const [withOutFareRows, setWithOutFareRows] = useState([]);
	const [withFareRows, setWithFareRows] = useState([]);
	const [specialtyRows, setSpecialtyRows] = useState([]);
	const [selected, setSelected] = useState([]);

	const filtersAtom = useAtomValue(initialFilterStationAtom);
	const filterDataListAtom = useAtomValue(filterDataList);

	const tableContainerRef = React.useRef('tableContainerRef');

	const getVacationsData = (data, setRows) => {
		const rows = [];
		let vacations = Object.entries(data);
		vacations.forEach((vacation) => {
			rows.push(
				createCreditsTableRowData(vacation?.[0] || '', vacation?.[1] || '', '')
			);
		});

		const sortedRows = rows.sort((a, b) => {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});

		setRows(sortedRows);
	};

	useEffect(() => {
		const getData = async () => {
			const ref = collection(db, 'vacationCredits');
			const q = query(ref, where(documentId(), '==', 'nSBBdPB7MT4UsEGFEbC9'));
			const unsub = onSnapshot(q, (querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setDocData(doc.data());
					const withOutFairData = doc.data()?.['Vacations without airfare'];
					const withFairData = doc.data()?.['Vacations with airfare'];
					const specialtyData = doc.data()?.['Specialty Vacations'];
					getVacationsData(withOutFairData, setWithOutFareRows);
					getVacationsData(withFairData, setWithFareRows);
					getVacationsData(specialtyData, setSpecialtyRows);
				});
			});
			return unsub;
		};
		openModal && getData();
	}, [openModal, onClose]);

	const handleCloseModal = (event, reason) => {
		// if click outside the modal or press esc key, don't close the modal
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		const tableScroll = tableContainerRef.current;
		tableScroll.scrollTop = 0;
		setOpenVacationTripsModal(false);
		onClose();
		setSelected([]);
	};

	const handleClick = (name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// select all rows in button
	const handleSelectAllClick = () => {
		// select all rows in each table and active checkbox
		// Concatenate all row names into a single array
		const allRows = [...withOutFareRows, ...withFareRows, ...specialtyRows].map(
			(row) => row.name
		);
		// Update the selected state with all row names
		setSelected(allRows);
	};

	// deselect all rows
	const handleDeselectAllClick = () => {
		setSelected([]);
	};

	// select default rows in base a list
	const handleSelectDefaultClick = () => {
		// Get the default rows from the constants file
		setSelected(defaultRowsVacations);
	};

	const getObjectData = (data) => {
		const objectKeys = Object.keys(data);
		// get the differente bettwen the data and selected table rows data
		let unselectedWithoutAirFareKeys = objectKeys.filter(
			(x) => !selected.includes(x)
		);

		// set "N/A" for the unselected rows
		unselectedWithoutAirFareKeys.forEach((key) => {
			data[key] = 'N/A';
		});
		// return the final data
		return data;
	};

	// The default vacation is automatically selected
	useEffect(() => {
		// if the modal is open, select the default rows
		handleSelectDefaultClick();
		// every time the modal is open, the default rows are selected
	}, [openModal]);

	const validateCredits = (allInputs) => {
		if (
			allInputs[0].name === 'Vacations' &&
			allInputs[0].cogs !== '' &&
			allInputs[0].cogs !== '0' &&
			allInputs[0].cogs !== 0
		) {
			return true;
		}
		return false;
	};
	const saveCredits = async () => {
		setOpenVacationTripsModal(false);
		try {
			const withOutFairData = docData?.['Vacations without airfare'];
			const withFairData = docData?.['Vacations with airfare'];
			const specialtyData = docData?.['Specialty Vacations'];

			const withOutFairFinalData = getObjectData(withOutFairData);
			const withFairFinalData = getObjectData(withFairData);
			const specialtyFinalData = getObjectData(specialtyData);

			let mainObj = {
				filters: {
					filters: filtersAtom,
					filterDataList: filterDataListAtom,
				},
			};
			// create data object to update proposal
			const data = {
				'Vacations without airfare': withOutFairFinalData,
				'Vacations with airfare': withFairFinalData,
				'Specialty Vacations': specialtyFinalData,
			};
			// set the vacation credits in the state
			setCurrentVacationCredits(validateCredits(allInputs) ? data : null);
			setVacationCredits(data);
			// close the modal
			handleCloseModal();

			const generalObject = (optionId) => {
				return {
					mainObj,
					vacationCredits: data,
					rewards,
					optionData,
					isAqhOption,
					isEditAqhOption,
					setOptionId,
					aqhSavedOptions,
					docId,
					optionId,
					createNewOne: isNewOne,
					proposalObj,
					setProposalId,
					setAqhSavedOptions,
				};
			};
			// get proposal status to verify if the proposal is approved or denied
			const proposalStatus = docId ? await proposalStatusReview(docId) : 'unread';

			// get the actual proposal state
			const actualProposalState =
				proposalStatus && proposalStatus !== 'unread' // if proposal has a status, use query status
					? proposalStatus // if proposal has no status, use proposalState
					: proposalState; // state from data in father component

			// open reward modal if the user has selected a reward
			if (
				(allInputs[1]?.revenue !== '' && allInputs[1]?.cogs !== '') ||
				(allInputs[2]?.revenue !== '' && allInputs[2]?.cogs !== '') ||
				(allInputs[3]?.revenue !== '' && allInputs[3]?.cogs !== '')
			) {
				setOpenRewardsModal(true);
			} else if (actualProposalState === 'approved') {
				await updateOrCreateOptionData(generalObject(optionId));
				if (isPublish) {
					openPublishQuoteModal(true);
				}
				setIsNewOne(false);
			} else if (actualProposalState === 'denied') {
				// if proposal is denied, update proposal data if is necessary
				await updateOrCreateOptionData(generalObject(optionId));
				if (isPublish) {
					openOverrideModal(true);
				}
			} else {
				if (isPublish) {
					await updateOrCreateOptionData(generalObject(optionData?.id));
					const result = await validateOptionErrors(aqhSavedOptions);
					const vacationsResult = await validateVacationCredits(aqhSavedOptions);
					// if one saved option in the proposal has errors, show manager override modal
					if (vacationsResult.includes(true) && result.includes(true)) {
						setOpenAirportsModal(true);
					} else if (!vacationsResult.includes(true) && result.includes(true)) {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// if one saved option in the proposal has errors, show manager override modal

							openOverrideModal(true);
						}
					} else if (vacationsResult.includes(true) && !result.includes(true)) {
						setOpenAirportsModal(true);
					} else {
						// If dealTerms is true, show Rollover modal
						if (dealTerms !== null) {
							setOpenRolloverModal(true);
						} else {
							// open publish quote modal
							openPublishQuoteModal(true);
						}
					}
				} else {
					await updateOrCreateOptionData(generalObject(optionId));
				}
				setIsNewOne(false);
			}
		} catch (error) {
			console.log('Error updating proposal: ', error);
		}
	};

	return (
		<>
			<Dialog
				open={openModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseModal}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '600px',
						minHeight: '200px',
					},
				}}
			>
				<DialogTitle sx={{ pb: 0 }}>
					<Grid container spacing={0.5} pb={1} justifyContent={'space-between'}>
						<Grid item>Select Vacation Trips</Grid>
						<Grid item>
							<Button
								size="small"
								variant="outlined"
								sx={{ borderRadius: 20, textTransform: 'capitalize', mr: 1 }}
								color="primary"
								onClick={handleSelectDefaultClick}
							>
								Select Defaults
							</Button>
							<Button
								size="small"
								variant="outlined"
								sx={{ borderRadius: 20, textTransform: 'capitalize', mr: 1 }}
								color="success"
								onClick={handleSelectAllClick}
							>
								Select All
							</Button>
							<Button
								size="small"
								variant="outlined"
								sx={{ borderRadius: 20, textTransform: 'capitalize' }}
								color="error"
								disabled={selected.length === 0}
								onClick={handleDeselectAllClick}
							>
								Clear All
							</Button>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent sx={{ alignItems: 'center' }}>
					{
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							sx={{ width: '100%' }}
						>
							<Paper sx={{ width: '100%', mb: 2 }}>
								<TableContainer
									sx={{ maxWidth: 570, maxHeight: 550 }}
									ref={tableContainerRef}
								>
									<Table
										// vacations without air fare table
										stickyHeader
										aria-label="sticky table"
									>
										<TableHead>
											<TableRow>
												{withOutairFareCells.map((column) => (
													<TableCell
														sx={{
															backgroundColor: '#6C6767',
															color: 'white',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
														key={column.id}
														align={column.align}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{withOutFareRows
												? withOutFareRows.map((row, index) => {
														return (
															<TableRow
																hover
																onClick={(_) => handleClick(row.name)}
																role="checkbox"
																aria-checked={isSelected(row.name)}
																tabIndex={-1}
																key={row.name}
																selected={isSelected(row.name)}
																sx={{ cursor: 'pointer' }}
															>
																<TableCell padding="checkbox">
																	<Checkbox color="primary" checked={isSelected(row.name)} />
																</TableCell>
																<TableCell
																	component="th"
																	id={row.name}
																	scope="row"
																	padding="none"
																>
																	{row.name}
																</TableCell>
																<TableCell align="center">{row.credits}</TableCell>
															</TableRow>
														);
												  })
												: null}
										</TableBody>
									</Table>

									<Table
										// vacations with air fare table
										sx={{ marginTop: 10 }}
										stickyHeader
										aria-label="sticky table"
									>
										<TableHead>
											<TableRow>
												{withAirFareCells.map((column) => (
													<TableCell
														sx={{
															backgroundColor: '#6C6767',
															color: 'white',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
														key={column.id}
														align={column.align}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{withFareRows
												? withFareRows.map((row, index) => {
														return (
															<TableRow
																hover
																onClick={(_) => handleClick(row.name)}
																role="checkbox"
																aria-checked={isSelected(row.name)}
																tabIndex={-1}
																key={row.name}
																selected={isSelected(row.name)}
																sx={{ cursor: 'pointer' }}
															>
																<TableCell padding="checkbox">
																	<Checkbox color="primary" checked={isSelected(row.name)} />
																</TableCell>
																<TableCell
																	component="th"
																	id={row.name}
																	scope="row"
																	padding="none"
																>
																	{row.name}
																</TableCell>
																<TableCell align="center">{row.credits}</TableCell>
															</TableRow>
														);
												  })
												: null}
										</TableBody>
									</Table>

									<Table
										// specialty table
										sx={{ marginTop: 10 }}
										stickyHeader
										aria-label="sticky table"
									>
										<TableHead>
											<TableRow>
												{specialtyCells.map((column) => (
													<TableCell
														sx={{
															backgroundColor: '#6C6767',
															color: 'white',
															paddingTop: 0.5,
															paddingBottom: 0.5,
														}}
														key={column.id}
														align={column.align}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{specialtyRows
												? specialtyRows.map((row, index) => {
														return (
															<TableRow
																hover
																onClick={(_) => handleClick(row.name)}
																role="checkbox"
																aria-checked={isSelected(row.name)}
																tabIndex={-1}
																key={row.name}
																selected={isSelected(row.name)}
																sx={{ cursor: 'pointer' }}
															>
																<TableCell padding="checkbox">
																	<Checkbox color="primary" checked={isSelected(row.name)} />
																</TableCell>
																<TableCell
																	component="th"
																	id={row.name}
																	scope="row"
																	padding="none"
																>
																	{row.name}
																</TableCell>
																<TableCell align="center">{row.credits}</TableCell>
															</TableRow>
														);
												  })
												: null}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
							<Button
								size="medium"
								variant="contained"
								color="success"
								onClick={saveCredits}
								disabled={selected.length === 0}
							>
								Submit
							</Button>
						</Box>
					}
				</DialogContent>
			</Dialog>
		</>
	);
}
