/* eslint-disable react-hooks/exhaustive-deps */
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { createContext, useReducer, useEffect } from 'react';
import { db } from '../firebase/config';
import useAuthContext from '../hooks/useAuthContext';
import { getEmployeeId } from '../utils';

export const ProfileContext = createContext();

export const profileReducer = (state, action) => {
	switch (action.type) {
		case 'addProfile':
			return { profile: action.payload };
		case 'clearProfile':
			return { profile: null };
		default:
			return state;
	}
};

export const ProfileContextProvider = ({ children }) => {
	const { authIsReady, user } = useAuthContext();
	const [state, dispatch] = useReducer(profileReducer, {
		profile: null,
	});

	useEffect(() => {
		if (authIsReady) {
			const docRef = doc(db, 'users', user.uid);
			const unsub = onSnapshot(docRef, async (profileSnap) => {
				if (profileSnap.exists()) {
					let { firstName, lastName, employeeId } = profileSnap.data();
					let profileObj = { ...profileSnap.data() };
					if (!employeeId) {
						//employeedId not exists then create one time unchangeable
						let userEmployeeId = getEmployeeId(firstName, lastName, 22);
						await updateDoc(docRef, { employeeId: userEmployeeId })
							.then(() => {
								Object.assign(profileObj, { employeeId: userEmployeeId });
							})
							.catch((err) => {
								return alert(err.message);
							});
					}
					dispatch({ type: 'addProfile', payload: profileObj });
				}
			});
			return () => unsub();
		}
	}, [authIsReady]);

	return (
		<ProfileContext.Provider value={{ ...state, dispatch }}>
			{children}
		</ProfileContext.Provider>
	);
};
