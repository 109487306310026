import React, { useState } from 'react';
import { Button } from '@mui/material';
import ReportsModal from './ReportsModal';

const ReportsGiftCards = () => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button variant="contained" onClick={() => setOpen(true)}>
				Reports
			</Button>
			<ReportsModal open={open} onClose={() => setOpen(false)} />
		</>
	);
};

export default ReportsGiftCards;
