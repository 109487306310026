import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

const isMatchingReward = (reward, record) => {
	// Validate if the record has a date
	const rewardDate =
		reward.date && reward.date.seconds
			? moment(reward.date.seconds * 1000)
			: null;

	const recordDate = record.date ? moment(record.date, 'DD/MM/YYYY') : null;

	// Return true if date, quantity and value match
	return (
		rewardDate &&
		recordDate &&
		rewardDate.isSame(recordDate, 'day') &&
		reward.quantity === record.quantity &&
		reward.value === record.value
	);
};

export const updateRewardHistory = async (record, newValues) => {
	try {
		const { contractsInfo } = record;
		const rewards = contractsInfo.mainObj?.rewards || {};
		const amazonRewards = rewards.amazon || [];

		const updatedAmazonRewards = amazonRewards.map((reward) => {
			if (isMatchingReward(reward, record)) {
				const newHistoryEntry = {
					date: new Date(),
					quantity: newValues.quantity,
					value: newValues.value,
				};

				const history = reward.history || [
					{
						date: reward.date,
						quantity: reward.quantity,
						value: reward.value,
						original: true,
					},
				];

				return {
					...reward,
					...newValues,
					history: [...history, newHistoryEntry],
				};
			}
			return reward;
		});
		// Save history in the database
		await updateDoc(doc(db, 'contracts', record.contractId), {
			'mainObj.rewards.amazon': updatedAmazonRewards,
		});
	} catch (error) {
		console.error('Error updating reward history:', error);
	}
};
