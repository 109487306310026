import React, { useState, useEffect, useCallback } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Box,
	CircularProgress,
	Typography,
} from '@mui/material';
import { useOrderDay } from '../../hooks/useOrderDay';

const SetOrdersDay = ({ open, onClose }) => {
	const { orderDay, loading, saveOrderDay } = useOrderDay();
	const [day, setDay] = useState('');

	const handleDayInput = useCallback((e) => {
		const value = e.target.value;
		if (
			value === '' ||
			(/^\d+$/.test(value) && parseInt(value) >= 1 && parseInt(value) <= 31)
		) {
			setDay(value);
		}
	}, []);

	// Clean state and close dialog
	const handleClose = useCallback(() => {
		setDay('');
		onClose();
	}, [onClose]);

	const handleSave = useCallback(async () => {
		try {
			await saveOrderDay(day);
			onClose();
		} catch (error) {
			console.error(error);
		}
	}, [day, saveOrderDay, onClose]);

	useEffect(() => {
		if (open) {
			setDay(orderDay);
		}
	}, [open, orderDay]);

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>Set Order Day</DialogTitle>

			<DialogContent sx={{ p: 3 }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						alignItems: 'center',
					}}
				>
					<Typography variant="subtitle1" textAlign="center" color="text.secondary">
						Choose the day of the month when you want your orders to be automatically
						placed. This will be your recurring monthly order date.
					</Typography>

					<TextField
						label="Day"
						type="number"
						value={day}
						onChange={handleDayInput}
						sx={{
							width: '100px',
							'& input': {
								fontSize: '18px',
								textAlign: 'center',
							},
						}}
						inputProps={{
							min: 1,
							max: 31,
							pattern: '[0-9]*',
						}}
					/>

					{day && (
						<Typography variant="subtitle1" color="primary" sx={{ mt: 2 }}>
							Orders will be processed on the {day} of each month
						</Typography>
					)}
				</Box>
			</DialogContent>

			<DialogActions sx={{ p: 2, gap: 1 }}>
				<Button variant="contained" color="error" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="success"
					onClick={handleSave}
					disabled={!day || parseInt(day) < 1 || parseInt(day) > 31}
					sx={{ minWidth: 100 }}
				>
					{loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SetOrdersDay;
