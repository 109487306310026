/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useContracts from './useContracts';
import moment from 'moment';

// Verify if the contract end date is after today
const isValidEndDate = (endDate) => {
	if (!endDate || endDate === 'n/a' || endDate === 'N/A') return false;
	return moment(endDate.seconds * 1000).isSameOrAfter(moment(), 'day');
};

// Check if the contract has a 'amazon' calculation with `cogs` greater than 0
const hasAmazonReward = (dealCalculations) => {
	return dealCalculations?.some(
		(item) => item.name === 'amazon' && item.cogs > 0
	);
};

// Check if the contract has a reward for the current month and year
const isValidRewardDate = (reward, month, year) => {
	if (!reward?.date?.seconds) return false;
	const rewardDate = moment(reward.date.seconds * 1000);
	return rewardDate.month() === month && rewardDate.year() === year;
};

const useGetAmazonCards = (month, year) => {
	const [orderCards, setOrderCards] = useState([]);
	const { contracts } = useContracts();

	const getActiveContracts = (contracts) => {
		return contracts.filter((doc) => {
			try {
				const { contractsInfo } = doc;
				const mainObj = contractsInfo?.mainObj;
				const agreementNumber = mainObj.agreementNumber || doc.nContract;

				if (!agreementNumber) return false;
				if (!isValidEndDate(mainObj?.endDate)) return false;
				if (!hasAmazonReward(mainObj?.dealCalculations)) return false;
				if (!mainObj?.rewards?.amazon) return false;

				return mainObj.rewards.amazon.some((reward) =>
					isValidRewardDate(reward, month, year)
				);
			} catch (error) {
				console.error('Error filtering contract:', error.message);
				return false;
			}
		});
	};

	const handleAmazonRewards = (reward, doc) => {
		const { contractsInfo } = doc;
		const { mainObj } = contractsInfo;
		// Get the station name
		const stationKey =
			mainObj.selectedRowsAqh?.[0]?.Station?.trim().toUpperCase();
		const orderTotal = Number(reward.quantity) * Number(reward.value);

		return {
			contractId: doc.id,
			contractName: contractsInfo.contractName || '',
			dealNumber: doc.nContract || mainObj.agreementNumber,
			mainStation: stationKey,
			employee: contractsInfo.employeeName,
			employeeEmail: contractsInfo.email,
			date: moment(reward.date.seconds * 1000).format('DD/MM/YYYY'),
			quantity: reward.quantity,
			value: reward.value,
			balance: orderTotal,
			contractsInfo,
		};
	};

	const handleOrderContracts = (contractsFiltered) => {
		return contractsFiltered.flatMap((doc) => {
			try {
				const amazonRewards = doc.contractsInfo?.mainObj?.rewards?.amazon || [];
				return amazonRewards
					.filter((reward) => isValidRewardDate(reward, month, year))
					.map((reward) => handleAmazonRewards(reward, doc));
			} catch (error) {
				console.error('Error processing contract:', error.message);
				return [];
			}
		});
	};

	useEffect(() => {
		if (!contracts?.length) return;

		const activeContracts = getActiveContracts(contracts);
		const currentOrders = handleOrderContracts(activeContracts);
		setOrderCards(currentOrders);
	}, [contracts, month, year]);

	return orderCards;
};

export default useGetAmazonCards;
