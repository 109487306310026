import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import {
	useTheme,
	ThemeProvider,
	Typography,
	Checkbox,
	IconButton,
	Stack,
	Tooltip,
	Button,
	Box,
	Alert,
} from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import useGetOrderCards from '../../hooks/useGetOrderCards';
import { optionsTableDefault } from './utils';
import moment from 'moment';
import { createUniqueId, handleCheckboxChange } from './utils';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EditModal from './EditReward';
import HistoryRewardChanges from './HistoryRewardChanges';
import SetOrdersDay from './SetOrdersDay';
import { useOrderDay } from '../../hooks/useOrderDay';
import ChangeOrderDate from './ChangeOrderDate';

const NextRewardCards = () => {
	const { orderDay } = useOrderDay();
	// Get the order cards records for the next month
	const currentMonth = moment().month();
	const nextMonth = (currentMonth + 1) % 12; // Will be 0 for January if current is December
	const currentYear =
		currentMonth === 11 ? moment().year() + 1 : moment().year();
	const orderCards = useGetOrderCards(nextMonth, currentYear);
	const theme = useTheme();
	const [checkedState, setCheckedState] = useState({});
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [openEdit, setOpenEdit] = useState(false);
	const [openHistory, setOpenHistory] = useState(false);
	const [openDayDialog, setOpenDayDialog] = useState(false);
	const [openChangeOrderDate, setOpenChangeOrderDate] = useState(false);
	const nextOrderMonth = moment().add(1, 'month').format('MMMM');
	const nextOrderDate = moment().add(1, 'month').set('date', orderDay);
	const formattedNextOrderDate = nextOrderDate.format('MMMM Do');

	const handleEdit = (order) => {
		setSelectedOrder(order);
		setOpenEdit(true);
	};

	const handleHistory = (order) => {
		setSelectedOrder(order);
		setOpenHistory(true);
	};

	const handleChangeOrderDate = (order) => {
		setSelectedOrder(order);
		setOpenChangeOrderDate(true);
	};

	const canEditReward = () => {
		const now = moment();
		const lastDayOfMonth = moment().endOf('month');
		const firstDayOfMonth = moment().startOf('month');

		// If it is the last day of the month and after 11:00pm
		if (now.isSame(lastDayOfMonth, 'day') && now.hour() >= 23) {
			return false;
		}

		// If it is the first day of the month and before 7:00am
		if (now.isSame(firstDayOfMonth, 'day') && now.hour() < 7) {
			return false;
		}

		return true;
	};

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},

		{
			name: 'date',
			label: 'Date',
		},
		{
			name: 'contractName',
			label: 'Contract Name',
		},
		{
			name: 'balance',
			label: 'balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'quantity',
			label: 'Number Of Cards',
		},
		{
			name: 'value',
			label: 'Denomination',
		},
		{
			name: 'manually',
			label: 'Manually',
			options: {
				customBodyRender: (value, tableMeta) => {
					// Get the record and create a unique ID
					const record = orderCards[tableMeta.rowIndex];
					const uniqueId = createUniqueId(record, tableMeta.rowIndex); // Create a unique ID

					return (
						<Checkbox
							checked={checkedState[uniqueId] || value === true}
							onChange={(e) =>
								handleCheckboxChange(
									record,
									e.target.checked,
									tableMeta.rowIndex,
									nextMonth,
									currentYear,
									setCheckedState
								)
							}
						/>
					);
				},
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			width: '700px',
			options: {
				customBodyRender: (value, tableMeta) => {
					const record = orderCards[tableMeta.rowIndex];
					return (
						<Stack direction="row" spacing={1}>
							<Tooltip
								title={
									!canEditReward()
										? 'No editing after 11:00 PM on the last day of the month until 4:00 AM on the first day of the following month.'
										: 'Edit'
								}
								placement="top"
							>
								<span>
									<IconButton
										size="small"
										onClick={() => handleEdit(record)}
										color="primary"
										disabled={!canEditReward()}
									>
										<EditIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="History" placement="top">
								<IconButton
									size="small"
									onClick={() => handleHistory(record)}
									color="primary"
								>
									<HistoryIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Change Date" placement="top">
								<IconButton
									size="small"
									color="success"
									onClick={() => handleChangeOrderDate(record)}
								>
									<ChangeCircleIcon />
								</IconButton>
							</Tooltip>
						</Stack>
					);
				},
			},
		},
	];

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				mb={2}
			>
				<Box display="flex" alignItems="center" gap={2}>
					<Typography variant="h6">Orders for {nextOrderMonth}</Typography>
					<Alert severity="info">
						Orders will be processed on {formattedNextOrderDate}
					</Alert>
				</Box>
				<Button variant="contained" onClick={() => setOpenDayDialog(true)}>
					Set Order Day
				</Button>
			</Stack>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCards || []}
						columns={columns}
						options={{
							...optionsTableDefault,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
				</ThemeProvider>
			</MainBox>
			<EditModal
				open={openEdit}
				order={selectedOrder}
				onClose={() => setOpenEdit(false)}
			/>
			<HistoryRewardChanges
				open={openHistory}
				order={selectedOrder}
				onClose={() => setOpenHistory(false)}
			/>
			<SetOrdersDay open={openDayDialog} onClose={() => setOpenDayDialog(false)} />
			<ChangeOrderDate
				open={openChangeOrderDate}
				onClose={() => setOpenChangeOrderDate(false)}
				currentOrder={selectedOrder}
			/>
		</>
	);
};
export default NextRewardCards;
