/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Messages from './components/messages';
import { db } from '../../firebase/config';
import {
	collection,
	doc,
	limit,
	onSnapshot,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import useAuthContext from '../../hooks/useAuthContext';
import TabPanel from '../Documents/components/TabPanel';
import Notifications from './components/notification';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function Notification({ receivedNoti }) {
	const { user } = useAuthContext();
	const [currentUser, setCurrentUser] = React.useState(null);
	const [value, setValue] = React.useState(0);
	const [receivedMsgs, setReceivedMsgs] = React.useState([]);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		user && setCurrentUser(user);
	}, [user]);

	React.useEffect(() => {
		if (receivedNoti) {
			const unseen = receivedNoti?.filter((v) => !v.seen);
			unseen?.forEach((v) => {
				const ref = doc(db, 'notifications', v.id);
				setTimeout(async () => {
					await updateDoc(ref, { seen: true });
				}, 3500);
			});
		}
	}, [receivedNoti]);

	React.useEffect(() => {
		if (db && currentUser) {
			const msgRef = collection(db, 'messages');
			const receiverQ = query(
				msgRef,
				where('receiver_id', '==', currentUser?.uid),
				// orderBy("createdAt", "desc")
				limit(500)
			);
			const unsubcribe = onSnapshot(receiverQ, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({
						...doc.data(),
						id: doc.id,
						received_message: doc.data().message,
					});
				});
				setReceivedMsgs(results);
				// bottomRefSend && bottomRefSend.current?.scrollIntoView({ behavior: 'smooth' });
				// bottomRefRec && bottomRefRec.current?.scrollIntoView({ behavior: 'smooth' });
			});
			return unsubcribe;
		}
	}, [db, currentUser]);

	return (
		<Box sx={{ width: '100%', height: '99%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs Messages n notifications"
					centered
				>
					<Tab label="Notifications" href="#notifications" {...a11yProps(0)} />
					<Tab label="Messages" href="#messages" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				{receivedNoti?.map((noti, i) => (
					<Notifications noti={noti} key={i} index={i} uid={currentUser?.uid} />
				))}
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Messages receivedMsgs={receivedMsgs} uid={currentUser?.uid} />
			</TabPanel>
		</Box>
	);
}

export default Notification;
