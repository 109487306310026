/* eslint-disable  react-hooks/exhaustive-deps*/
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getNotesById } from '../../utils/utils';
import AcceptedItem from './AcceptedItem';
import History from '../HistoryNotes/History';
const HistoryNotes = ({ open, closeModal, itemData, isBillPayManager }) => {
	const [query, setQuery] = useState();
	useEffect(() => {
		if (itemData) {
			if (itemData.id && itemData.contractId) {
				const query = getNotesById(itemData.id, itemData.contractId);
				setQuery(query);
			}
		}
	}, [itemData]);

	return (
		<Dialog
			open={open}
			disableAutoFocus
			onClose={closeModal}
			PaperProps={{
				style: { overflow: 'hidden' },
			}}
			maxWidth={'md'}
			fullWidth={true}
		>
			<DialogTitle>Notes</DialogTitle>
			<DialogContent>
				<History query={query} key={'history'} slug={'notes'}></History>
			</DialogContent>
			{itemData && (
				<AcceptedItem
					isBillPayManager={isBillPayManager}
					contractId={itemData.contractId}
					item={itemData}
					itemId={itemData.id}
				></AcceptedItem>
			)}
		</Dialog>
	);
};
export default HistoryNotes;
