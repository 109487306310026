import { useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../../utils/index';

const useClientsDownload = () => {
	const [state, setState] = useState({
		loading: false,
		error: null,
	});

	// Function to create headers for CSV file
	const createHeaders = (data) => {
		if (!data || !Object.keys(data).length) return [];
		return Object.keys(data).map((key) => ({
			label: key
				.replace(/([A-Z])/g, ' $1')
				.replace(/^./, (str) => str.toUpperCase()),
			key,
		}));
	};

	// Function to format CSV values
	const formatCSV = (value) => {
		if (value === null || value === undefined) return '';
		const stringValue = String(value).trim();
		return stringValue.includes(',') ||
			stringValue.includes('"') ||
			stringValue.includes('\n')
			? `"${stringValue.replace(/"/g, '""')}"`
			: stringValue;
	};

	// Function to create CSV content
	const createCSVContent = (data, headers) => {
		const headerRow = headers.map((h) => formatCSV(h.label));
		const dataRows = data.map((row) => headers.map((h) => formatCSV(row[h.key])));
		return [headerRow, ...dataRows].map((row) => row.join(',')).join('\n');
	};

	const downloadFile = (content, filename) => {
		const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');

		try {
			link.href = url;
			link.download = filename;
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.error('Error downloading file', error);
			throw new Error('Error downloading file');
		} finally {
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		}
	};

	const downloadCSV = async () => {
		setState({ loading: true, error: null });

		try {
			// Get clients data from Dash Solutions API
			const { data } = await axios.post(baseURL + '/getClientsDashSolutions');
			const clientsData = Array.isArray(data) ? data : [];

			if (!clientsData.length) {
				console.log('No data available for export');
			}

			// Handle CSV file creation
			const headers = createHeaders(clientsData[0]);
			const csvContent = createCSVContent(clientsData, headers);
			const filename = `Clients_${new Date().toISOString().split('T')[0]}.csv`;

			await downloadFile(csvContent, filename);
			setState({ loading: false, error: null });
		} catch (error) {
			setState({
				loading: false,
				error: error.message || 'Error downloading file',
			});
		}
	};

	return {
		downloadCSV,
		loading: state.loading,
		error: state.error,
	};
};

export default useClientsDownload;
