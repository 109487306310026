/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useContracts from './useContracts';
import axios from 'axios';
import { baseURL } from '../../../utils';
import moment from 'moment';

// Find client info by Call Letters in Dash Solutions
const findClientInfo = (mainStation, clients) => {
	// Check if mainStation and clients exist
	if (!mainStation || !clients?.length) {
		return null;
	}
	const mainStationContract = mainStation.trim().toUpperCase();
	const client = clients.find((client) => {
		if (!client?.callLetters) return false;

		const clientCallLetters = client.callLetters.trim().toUpperCase();
		return clientCallLetters === mainStationContract;
	});

	return client;
};

const useReportGiftCards = (month, year) => {
	const [state, setState] = useState({
		orderCards: [],
		loading: false,
		error: null,
	});

	const { contracts, loading: contractsLoading } = useContracts();

	const formatReportData = (orders) => {
		return orders.map((order) => ({
			rep: order.employee || '',
			dealNumber: Number(order.dealNumber) || '',
			contactName: order.clientInfo?.contactName || '',
			contactPhone: order.clientInfo?.contactPhone || '',
			contactEmail: order.clientInfo?.contactEmail || '',
			address: order.clientInfo?.address1
				? `${order.clientInfo.address1}, ${order.clientInfo.address2 || ''}`
				: '',
			totalValue: order.totalOrder || 0,
			denomination: order.value || 0,
		}));
	};

	useEffect(() => {
		const fetchData = async () => {
			if (!month || !year || !contracts) {
				setState((prev) => ({ ...prev, orderCards: [] }));
				return;
			}

			setState((prev) => ({ ...prev, loading: true }));

			try {
				// Get clients data from Dash Solutions API
				const { data: clientsData } = await axios.post(
					baseURL + '/getClientsDashSolutions'
				);

				const ordersPromises = contracts.map(async (doc) => {
					const { contractsInfo } = doc;
					const mainObj = contractsInfo?.mainObj;
					const visaRewards = mainObj?.rewards?.visa || [];
					const mainStation = mainObj?.selectedRowsAqh?.[0]?.Station;

					// Find client info by Call Letters in Dash Solutions
					const clientInfo = findClientInfo(mainStation, clientsData);

					return visaRewards
						.filter((reward) => {
							const rewardDate = moment(reward?.date?.seconds * 1000);
							return (
								rewardDate.month() === moment().month(month).month() &&
								rewardDate.year() === parseInt(year)
							);
						})
						.map((reward) => ({
							contractId: doc.id,
							dealNumber: doc.nContract || mainObj.agreementNumber,
							employee: contractsInfo.employeeName,
							quantity: Number(reward.quantity),
							value: Number(reward.value),
							totalOrder: Number(reward.quantity) * Number(reward.value),
							clientInfo, // Client info from dash solutions
							mainStation,
						}));
				});

				const resolvedOrders = await Promise.all(ordersPromises);
				const flattenedOrders = resolvedOrders.flat();

				setState({
					orderCards: formatReportData(flattenedOrders),
					loading: false,
					error: null,
				});
			} catch (error) {
				console.error('Error processing orders', error);
				setState({
					orderCards: [],
					loading: false,
					error: error.message,
				});
			}
		};

		fetchData();
	}, [contracts, month, year]);

	return {
		...state,
		loading: state.loading || contractsLoading,
	};
};

export default useReportGiftCards;
