import React, { useState } from 'react';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import TabPanel from '../../../Documents/components/TabPanel';
import RepCardsActive from './RepCardsActive';
import CurrentAmazonOrders from './CurrentAmazonOrders';
import NextAmazonOrders from './NextAmazonOrders';

const RewardCardsBox = styled(Box)(({ theme }) => ({
	'& .MuiFormControl-root': {
		margin: 0,
	},
	'& .MuiAutocomplete-root': {
		width: '220px',
	},
}));

const a11yProps = (index) => ({
	id: `reward-tab-${index}`,
	'aria-controls': `reward-tabpanel-${index}`,
});

const RepCardsHome = () => {
	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<RewardCardsBox sx={{ width: '100%' }}>
			<Typography variant="h4">Amazon Cards</Typography>
			<Box
				sx={{
					height: '60px',
					display: 'flex',
					alignItems: 'flex-end',
					gap: '1.5rem',
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						aria-label="reward cards tabs"
					>
						<Tab label="Active Deals" {...a11yProps(0)} />
						<Tab label="Current Orders" {...a11yProps(1)} />
						<Tab label="Next Orders" {...a11yProps(2)} />
					</Tabs>
				</Box>
			</Box>

			<TabPanel value={tabValue} index={0}>
				<RepCardsActive />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<CurrentAmazonOrders />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<NextAmazonOrders />
			</TabPanel>
		</RewardCardsBox>
	);
};

export default RepCardsHome;
