import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import {
	useTheme,
	ThemeProvider,
	Typography,
	Checkbox,
	IconButton,
	Stack,
} from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import useGetOrderCards from '../../hooks/useGetOrderCards';
import moment from 'moment';
import {
	createUniqueId,
	handleCheckboxChange,
	optionsTableDefault,
} from './utils';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import EditModal from './EditReward';
import HistoryRewardChanges from './HistoryRewardChanges';

const CurrentRewardCards = () => {
	const currentMonth = moment().month();
	const currentYear = moment().year();
	const orderCards = useGetOrderCards(currentMonth, currentYear);
	const theme = useTheme();
	const [checkedState, setCheckedState] = useState({});
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [openEdit, setOpenEdit] = useState(false);
	const [openHistory, setOpenHistory] = useState(false);

	const handleEdit = (order) => {
		setSelectedOrder(order);
		setOpenEdit(true);
	};

	const handleHistory = (order) => {
		setSelectedOrder(order);
		setOpenHistory(true);
	};

	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},
		{
			name: 'date',
			label: 'Date',
		},
		{
			name: 'contractName',
			label: 'Contract Name',
		},
		{
			name: 'balance',
			label: 'Balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'quantity',
			label: 'Number Of Cards',
		},
		{
			name: 'value',
			label: 'Denomination',
		},
		{
			name: 'manually',
			label: 'Manually',
			options: {
				customBodyRender: (value, tableMeta) => {
					// Get the record and create a unique ID
					const record = orderCards[tableMeta.rowIndex];
					const uniqueId = createUniqueId(record, tableMeta.rowIndex);

					return (
						<Checkbox
							checked={checkedState[uniqueId] || value === true}
							onChange={(e) =>
								handleCheckboxChange(
									record,
									e.target.checked,
									tableMeta.rowIndex,
									currentMonth,
									currentYear,
									setCheckedState
								)
							}
						/>
					);
				},
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			options: {
				customBodyRender: (value, tableMeta) => {
					const record = orderCards[tableMeta.rowIndex];
					return (
						<Stack direction="row" spacing={1}>
							<IconButton
								size="small"
								onClick={() => handleEdit(record)}
								color="primary"
							>
								<EditIcon />
							</IconButton>
							<IconButton
								size="small"
								onClick={() => handleHistory(record)}
								color="primary"
							>
								<HistoryIcon />
							</IconButton>
						</Stack>
					);
				},
			},
		},
	];

	return (
		<>
			<Typography variant="h6">Orders for {moment().format('MMMM')}</Typography>
			<MainBox
				sx={{
					boxShadow: (theme) => theme.shadows[4],
					borderRadius: 2,
					paddingX: 1.2,
					marginX: -1,
				}}
			>
				<ThemeProvider theme={getTheme(theme)}>
					<MUIDataTable
						data={orderCards || []}
						columns={columns}
						options={{
							...optionsTableDefault,
							selectToolbarPlacement: 'replace',
							responsive: 'standard',
							selectableRows: 'none',
						}}
					></MUIDataTable>
				</ThemeProvider>
			</MainBox>
			<EditModal
				open={openEdit}
				order={selectedOrder}
				onClose={() => setOpenEdit(false)}
			/>
			<HistoryRewardChanges
				open={openHistory}
				order={selectedOrder}
				onClose={() => setOpenHistory(false)}
			/>
		</>
	);
};
export default CurrentRewardCards;
