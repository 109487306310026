import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { auth } from '../../firebase/config';
import { useSnackbar } from 'notistack';
import { sendPasswordResetEmail } from 'firebase/auth';

const FormBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#EFF1F4',
	borderRadius: '10px',
	border: '1px solid #B0B4B4',
	boxSizing: 'border-box',
	marginTop: `${theme.spacing(2)}`,
	padding: `${theme.spacing(3)}`,
	boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 0px',

	'& .MuiFormControl-root': {
		margin: '15px 0',
	},
	'& .checkboxContainer': {
		display: 'flex',
		alignItems: 'center',
		'& .MuiCheckbox-root': {
			paddingLeft: '1px',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			margin: `${theme.spacing(2)} 0`,
		},
	},
	'& button': {
		fontWeight: 'bold',
		color: '#fff',
		'&:last-of-type': {
			marginTop: '12px',
		},
	},
	'& a.MuiTypography-root': {
		fontWeight: 'bold',
		textDecoration: 'none',
		display: 'block',
		textAlign: 'center',
		marginTop: '12px',
	},
}));

const PasswordReset = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [email, setEmail] = useState('');

	const hanldePasswordReset = () => {
		sendPasswordResetEmail(auth, email)
			.then(() => {
				enqueueSnackbar('Password reset email sent!', { variant: 'success' });
				setEmail('');
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: 'error' });
			});
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '100px',
			}}
		>
			<Grid item xs={12} sm={8} md={6}>
				<FormBox>
					<Typography
						className="title"
						variant="h1"
						sx={{ mb: 3, fontSize: '1.7rem', fontWeight: 'bold' }}
						color="primary"
					>
						Password Reset
					</Typography>
					<TextField
						id="loginEmail"
						label="Email"
						variant="outlined"
						size="small"
						fullWidth
						value={email}
						placeholder="Enter Your login Email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						onClick={hanldePasswordReset}
					>
						Reset
					</Button>
				</FormBox>
			</Grid>
		</Grid>
	);
};

export default PasswordReset;
