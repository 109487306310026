import React, { useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import axios from 'axios';
import { baseURL } from '../../utils/index';

const LoadingState = () => (
	<Box
		sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100vh',
			gap: 2,
		}}
	>
		<Typography variant="h6" color="textSecondary">
			Loading Document...
		</Typography>
		<CircularProgress />
	</Box>
);

const GeneratePDF = ({ mainObj, showPdfPreview }) => {
	const [pdfUrl, setPdfUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const generatePDF = async () => {
		if (!mainObj) return;

		setLoading(true);
		setError(null);

		try {
			// Generar PDF in backend
			const response = await axios.post(
				baseURL + '/generatePreviewPDF',
				{ mainObj },
				{
					responseType: 'arraybuffer',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/pdf',
					},
				}
			);
			console.log('response:', response);
			// Create a Blob from the PDF Stream
			const blob = new Blob([response.data], { type: 'application/pdf' });
			const url = URL.createObjectURL(blob);
			setPdfUrl(url);
		} catch (err) {
			console.error('Error generando PDF:', err);
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		return () => {
			if (pdfUrl) {
				URL.revokeObjectURL(pdfUrl);
			}
		};
	}, [pdfUrl]);

	if (!showPdfPreview) return null;

	if (loading) {
		return <LoadingState />;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	// if no have pdfUrl, generate the PDF
	if (!pdfUrl) {
		generatePDF();
		return <CircularProgress />;
	}

	// Show the PDF
	return (
		<iframe
			src={pdfUrl}
			title="Contract Preview"
			style={{
				width: '100%',
				height: '100vh',
				border: 'none',
			}}
		/>
	);
};

export default GeneratePDF;
