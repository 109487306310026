import React from 'react';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { AuthContextProvider } from './context/AuthContext';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
	BrowserRouter,
	// Routes,
	// Route,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';
import { ProfileContextProvider } from './context/ProfileContext';
import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				BrowserRouter,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// define which domains are considered for tracing
	tracePropagationTargets: [
		// 'localhost', commented out because it's the default
		'sunfuncloud.com',
		/^https:\/\/yourserver\.io\/api/,
	],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

createRoot(document.getElementById('root')).render(
	<StrictMode>
		<AuthContextProvider>
			<ProfileContextProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ProfileContextProvider>
		</AuthContextProvider>
	</StrictMode>
);

reportWebVitals();
