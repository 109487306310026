import React, { useState, useEffect, useCallback } from 'react';
import {
	Select,
	Button,
	Typography,
	Box,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
} from '@mui/material';
import useReportGiftCards from '../../hooks/useReportGiftCards';
import DownloadReport from './DownloadReport';
import { months, years } from './utils';

const ReportsDialog = ({ open, onClose }) => {
	const [state, setState] = useState({
		month: '',
		year: '',
		isReportReady: false,
	});

	const { month, year, isReportReady } = state;
	const { orderCards, loading } = useReportGiftCards(month, year);
	const showNoResults = !loading && month && year && orderCards.length === 0;

	const handleInputChange = useCallback(
		(field) => (event) => {
			const value = event.target.value;
			setState((prev) => ({ ...prev, [field]: value }));
		},
		[]
	);

	useEffect(() => {
		if (!loading && orderCards.length > 0 && month && year) {
			setState((prev) => ({ ...prev, isReportReady: true }));
		} else {
			setState((prev) => ({ ...prev, isReportReady: false }));
		}
	}, [loading, orderCards.length, month, year]);

	const handleClose = useCallback(() => {
		setState({ month: '', year: '', isReportReady: false });
		onClose();
	}, [onClose]);

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>Reports</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						pt: 4,
						pb: 4,
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: 2,
							width: '100%',
						}}
					>
						<Box sx={{ width: '50%' }}>
							<Typography>Month</Typography>
							<Select
								fullWidth
								value={month}
								onChange={handleInputChange('month')}
								disabled={loading}
								displayEmpty
							>
								<MenuItem value="" disabled>
									Select month
								</MenuItem>
								{months.map((m) => (
									<MenuItem key={m} value={m}>
										{m}
									</MenuItem>
								))}
							</Select>
						</Box>
						<Box sx={{ width: '50%' }}>
							<Typography>Year</Typography>
							<Select
								fullWidth
								value={year}
								onChange={handleInputChange('year')}
								disabled={loading}
								displayEmpty
							>
								<MenuItem value="" disabled>
									Select year
								</MenuItem>
								{years.map((y) => (
									<MenuItem key={y} value={y}>
										{y}
									</MenuItem>
								))}
							</Select>
						</Box>
					</Box>

					{showNoResults && (
						<Typography color="error" align="center" className="mt-2">
							No results found for {month} {year}
						</Typography>
					)}

					{loading && (
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
							}}
						>
							<CircularProgress />
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					variant="contained"
					color="error"
					disabled={loading}
				>
					Cancel
				</Button>
				{isReportReady && (
					<DownloadReport data={orderCards} month={month} year={year} />
				)}
			</DialogActions>
		</Dialog>
	);
};

export default ReportsDialog;
