import { useState, useEffect } from 'react';
import { db } from '../../../firebase/config';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';

export const useOrderDay = () => {
	const [loading, setLoading] = useState(false);
	const [orderDay, setOrderDay] = useState('1');

	useEffect(() => {
		const docRef = doc(db, 'orderDay', 'current');
		const unsubscribe = onSnapshot(docRef, (docSnap) => {
			if (docSnap.exists()) {
				setOrderDay(docSnap.data().day.toString());
			}
		});
		return () => unsubscribe();
	}, []);

	const saveOrderDay = async (day) => {
		setLoading(true);
		try {
			await setDoc(doc(db, 'orderDay', 'current'), {
				day: parseInt(day),
				updatedAt: new Date(),
			});
		} catch (error) {
			console.error('Error saving orderDay:', error);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	return { orderDay, loading, saveOrderDay };
};
