/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useContracts from './useContracts';
import moment from 'moment';

const useGetOrderCards = (month, year) => {
	const [orderCards, setOrderCards] = useState([]);
	const { contracts } = useContracts();
	// Current month and year
	const currentMonth = month;
	const currentYear = year;

	useEffect(() => {
		if (contracts) {
			const getOrders = getActiveContracts(contracts);
			const currentOrders = handleOrderContracts(getOrders);
			setOrderCards(currentOrders);
		}
	}, [contracts]);

	const getActiveContracts = (contracts) => {
		// Filter contracts
		return contracts.filter((doc) => {
			try {
				const { contractsInfo } = doc;
				const mainObj = contractsInfo?.mainObj;

				// Check if agreementNumber is inside or outside mainObj
				const agreementNumber = mainObj.agreementNumber || doc.nContract;
				const { endDate, dealCalculations, rewards } = mainObj || {};

				// Verify if the contract has an agreement number
				if (!agreementNumber) {
					return false;
				}

				// Verify if the contract has an end date
				if (!endDate || endDate === 'n/a' || endDate === 'N/A') {
					return false;
				}

				// Verify if the contract end date is after today
				const endDateMillis = endDate.seconds * 1000;
				if (!moment(endDateMillis).isSameOrAfter(moment(), 'day')) {
					return false;
				}

				// Check if the contract has a 'visa' calculation with `cogs` greater than 0
				if (
					!dealCalculations ||
					!dealCalculations.some((item) => item.name === 'visa' && item.cogs > 0)
				) {
					return false;
				}

				// Check if rewards exists and has 'visa'
				if (!rewards || !rewards.visa) {
					return false;
				}

				// Save the reward dates
				const rewardDates = rewards.visa;

				// Check if the contract has a reward for the current month and year
				const hasCurrentMonthReward = rewardDates.some((reward) => {
					if (!reward.date || typeof reward.date.seconds !== 'number') {
						return false;
					}
					const rewardDateMillis = reward.date.seconds * 1000;
					const rewardDate = moment(rewardDateMillis);
					return (
						rewardDate.month() === currentMonth && rewardDate.year() === currentYear
					);
				});

				if (!hasCurrentMonthReward) {
					return false;
				}

				return true;
			} catch (error) {
				console.error('Error filtering contract:', error.message);
				return false;
			}
		});
	};

	const handleOrderContracts = (contractsFiltered) => {
		return contractsFiltered.flatMap((doc) => {
			try {
				const { contractsInfo } = doc;
				const { mainObj } = contractsInfo || {};
				const agreementNumber = doc.nContract || mainObj.agreementNumber;
				const visaRewards = mainObj.rewards?.visa || [];

				return visaRewards
					.filter((reward) => {
						const rewardDateMillis = reward?.date?.seconds * 1000;
						return (
							rewardDateMillis &&
							moment(rewardDateMillis).month() === currentMonth &&
							moment(rewardDateMillis).year() === currentYear
						);
					})
					.map((reward) => {
						// Get the station key
						const stationKey =
							mainObj.selectedRowsAqh?.[0]?.Station?.trim().toUpperCase();

						const orderTotal = Number(reward.quantity) * Number(reward.value);
						return {
							contractId: doc.id,
							contractName: contractsInfo.contractName || '',
							dealNumber: agreementNumber,
							mainStation: stationKey,
							employee: contractsInfo.employeeName,
							employeeEmail: contractsInfo.email,
							date: moment(reward.date.seconds * 1000).format('DD/MM/YYYY'),
							quantity: reward.quantity,
							value: reward.value,
							balance: orderTotal,
							manually: reward?.manually || false,
							contractsInfo,
						};
					});
			} catch (error) {
				console.error('Error processing contract:', error.message);
				return [];
			}
		});
	};

	return orderCards;
};

export default useGetOrderCards;
