import React, { useState, useCallback } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	CircularProgress,
	Typography,
	TextField,
} from '@mui/material';
import moment from 'moment';
import { format, addMonths, isSameMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { changeMonthOrder } from './utils';

const ChangeOrderDate = ({ open, onClose, currentOrder }) => {
	const [loading, setLoading] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);

	const handleClose = useCallback(() => {
		setSelectedDate(null);
		onClose();
	}, [onClose]);

	const isNextMonth = useCallback((date) => {
		const nextMonth = addMonths(new Date(), 1);
		return isSameMonth(date, nextMonth);
	}, []);

	const handleSave = async () => {
		setLoading(true);
		try {
			await changeMonthOrder(currentOrder, selectedDate);
			setSelectedDate(null);
			onClose();
		} catch (error) {
			console.error('Error updating reward date:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>Change Order Date</DialogTitle>
			<DialogContent sx={{ p: 3 }}>
				{currentOrder && (
					<Box
						sx={{
							bgcolor: 'grey.100',
							p: 2,
							borderRadius: 1,
							mb: 3,
						}}
					>
						<Typography variant="subtitle1" color="primary" gutterBottom>
							Order Selected
						</Typography>
						<Box sx={{ display: 'grid', gridTemplateColumns: '120px 1fr', gap: 2 }}>
							<Typography variant="subtitle2" color="text.secondary">
								Contract:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{currentOrder.contractName}
							</Typography>
							<Typography variant="subtitle2" color="text.secondary">
								Number of Cards:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>
								{currentOrder.quantity}
							</Typography>
							<Typography variant="subtitle2" color="text.secondary">
								Denomination:
							</Typography>
							<Typography sx={{ fontSize: '15px' }}>${currentOrder.value}</Typography>
						</Box>
					</Box>
				)}

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						alignItems: 'center',
					}}
				>
					<Typography variant="subtitle1" color="text.secondary">
						Choose the month to which you want to move the order.
					</Typography>

					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							open={openDatePicker}
							onOpen={() => setOpenDatePicker(true)}
							onClose={() => setOpenDatePicker(false)}
							label="New Order Date"
							value={selectedDate}
							views={['month']}
							onChange={(newValue) => {
								const date = new Date(newValue);
								date.setDate(15);
								setSelectedDate(date);
							}}
							InputProps={{
								value: selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '',
								inputProps: { readOnly: true },
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									sx={{ width: '180px' }}
									onClick={() => setOpenDatePicker(true)}
								/>
							)}
						/>
					</LocalizationProvider>

					{selectedDate && (
						<Typography variant="subtitle1" color="primary">
							{isNextMonth(selectedDate)
								? `The order is already in that month`
								: `Order will be moved to: ${moment(selectedDate).format(
										'DD/MM/YYYY'
								  )}`}
						</Typography>
					)}
				</Box>
			</DialogContent>

			<DialogActions sx={{ p: 2, gap: 1 }}>
				<Button variant="contained" color="error" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="success"
					onClick={handleSave}
					disabled={!selectedDate || loading || isNextMonth(selectedDate)}
					sx={{ minWidth: 100 }}
				>
					{loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ChangeOrderDate;
