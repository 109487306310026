import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Box,
	CircularProgress,
} from '@mui/material';
import { updateRewardHistory } from './utils';

const EditModal = ({ open, order, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		quantity: '',
		value: '',
	});

	const handleNumberInput = (e, field) => {
		const value = e.target.value;
		// Only allow numbers
		if (value === '' || /^\d+$/.test(value)) {
			setValues((prev) => ({
				...prev,
				[field]: value,
			}));
		}
	};
	const resetValues = () => {
		if (order) {
			setValues({
				quantity: String(order.quantity || ''),
				value: String(order.value || ''),
			});
		}
	};

	const handleClose = () => {
		resetValues();
		onClose();
	};

	useEffect(() => {
		if (order) {
			setValues({
				quantity: String(order.quantity || ''),
				value: String(order.value || ''),
			});
		}
	}, [order]);

	const handleSave = async () => {
		setLoading(true);
		try {
			await updateRewardHistory(order, values);
			onClose();
		} catch (error) {
			console.error(error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Edit Order</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
					<TextField
						label="Number of Cards"
						type="text"
						value={values.quantity}
						onChange={(e) => handleNumberInput(e, 'quantity')}
						inputProps={{ pattern: '[0-9]*' }}
						fullWidth
					/>
					<TextField
						label="Value"
						type="text"
						value={values.value}
						onChange={(e) => handleNumberInput(e, 'value')}
						inputProps={{ pattern: '[0-9]*' }}
						fullWidth
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="error" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					variant="contained"
					color="success"
					disabled={!values.quantity || !values.value}
				>
					{loading ? <CircularProgress size={24} color="inherit" /> : 'Edit'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditModal;
