/* eslint-disable react-hooks/exhaustive-deps */
import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	CircularProgress,
	Container,
	TextField,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
	addDoc,
	collection,
	limit,
	onSnapshot,
	query,
	serverTimestamp,
	where,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../../firebase/config';
import { useCollection } from '../../../hooks/useCollection';
const initialValue = {
	id: '',
	email: '',
	employeeId: '',
	firstName: '',
	lastName: '',
	photoURL: '',
	nickName: '',
};
function Messages({ receivedMsgs, uid }) {
	const bottomRefSend = useRef(null);
	const chatBox = useRef(null);
	const { documents: users } = useCollection('users');
	const [user, setUser] = useState(initialValue);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [chats, setChats] = useState([]);
	const [sendMsgs, setSendMsgs] = useState([]);
	const [scrollHere, setScrollHere] = useState(0);

	const [userData, setUserData] = useState(null);
	useEffect(() => {
		if (users) {
			setUserData(users.find(({ id }) => id === uid));
		}
	}, [users]);
	const handleChange = (value) => {
		setUser(value || initialValue);
		if (chatBox) {
			setTimeout(() => {
				chatBox.current?.scrollTo({
					top: chatBox.current?.scrollHeight,
					behavior: 'smooth',
				});
			}, 1000);
		}
		if (chatBox) {
			setScrollHere(chatBox.current?.scrollHeight);
		}
	};

	const handlePing = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const payload = {
				message: message.trim(),
				sender_id: uid,
				receiver_id: user.id,
				seen: false,
				createdAt: serverTimestamp(),
			};
			const payloadNoti = {
				title: `You got a new message`,
				message: message.trim(),
				sender_id: uid,
				receiver_id: user.id,
				seen: false,
				createdAt: serverTimestamp(),
			};
			const docRef = collection(db, 'messages');
			const notiRef = collection(db, 'notifications');
			await addDoc(docRef, payload);
			await addDoc(notiRef, payloadNoti);
			// enqueueSnackbar("Message sent!", { variant: "success" })
			setMessage('');
			setLoading(false);
		} catch (err) {
			setLoading(false);
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	useEffect(() => {
		if (db && uid) {
			const msgRef = collection(db, 'messages');
			const senderQ = query(
				msgRef,
				where('sender_id', '==', uid),
				// orderBy("createdAt", "desc")
				limit(500)
			);
			const unsubcribe = onSnapshot(senderQ, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({
						...doc.data(),
						id: doc.id,
						send_message: doc.data().message,
					});
				});
				setSendMsgs(results);
			});
			return unsubcribe;
		}
		setScrollHere(chatBox.current?.scrollHeight + 10);
	}, [db, uid]);

	useEffect(() => {
		if (receivedMsgs || sendMsgs) {
			setChats(
				[...sendMsgs, ...receivedMsgs].sort((a, b) => {
					const aDate = new Date(a?.createdAt?.toDate());
					const bDate = new Date(b?.createdAt?.toDate());

					return aDate.getTime() - bDate.getTime();
				})
			);
		}
		if (chatBox) {
			setScrollHere(chatBox.current?.scrollHeight);
		}
	}, [sendMsgs, receivedMsgs]);

	useEffect(() => {
		if (chatBox) {
			setTimeout(() => {
				chatBox.current?.scrollTo({ top: scrollHere, behavior: 'smooth' });
			}, 550);
		}
	}, [chatBox, scrollHere]);
	return (
		<Container sx={{ '& form': { display: 'flex' } }} id={'messagesHere'}>
			<Box sx={{ p: 3, height: '70vh', overflowY: 'auto', my: 1 }} ref={chatBox}>
				{chats &&
				chats.filter(
					({ receiver_id, sender_id }, i) =>
						receiver_id === user.id || sender_id === user.id
				).length > 0 ? (
					chats
						?.filter(
							({ receiver_id, sender_id }, i) =>
								receiver_id === user.id || sender_id === user.id
						)
						.map((v, i) => {
							const receiver = users?.find(({ id }) => id === v.sender_id);
							return (
								<Box key={i} ref={chats.length - 1 === i ? bottomRefSend : null}>
									{v.received_message && (
										<Box sx={{ width: '80%', mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													pr: 2,
													width: 'max-content',
													borderRadius: (theme) => theme.spacing(1),
													backgroundColor: (theme) => theme.palette.primary.main + '4f',
												}}
											>
												<Box sx={{ py: 2, pl: 2 }}>
													<Avatar
														src={
															(receiver && receiver?.photoURL) ||
															receiver?.email?.toUpperCase() ||
															''
														}
														alt="receiver_profile"
														sx={{ mr: 2 }}
													/>
												</Box>
												<Box sx={{ py: 2 }}>
													<Typography variant="body1" fontWeight={'bold'}>
														{receiver?.firstName
															? receiver['firstName'] + ' ' + receiver?.lastName
															: receiver?.email?.split('@')[0] || ''}
													</Typography>
													<Typography variant="body2" sx={{ maxWidth: '35em' }}>
														{v.received_message}
													</Typography>
												</Box>
											</Box>
											<Typography variant="caption">
												{format(
													new Date(v?.createdAt && v?.createdAt?.toDate()),
													'yyyy-MM-dd'
												) === format(new Date(), 'yyyy-MM-dd')
													? format(v?.createdAt?.toDate(), 'hh:mm a')
													: v?.createdAt?.toDate()?.toDateString()}
											</Typography>
										</Box>
									)}
									{v.send_message && (
										<Box sx={{ width: '80%', ml: 'auto', mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													pl: 2,
													width: 'max-content',
													ml: 'auto',
													justifyContent: 'end',
													borderRadius: (theme) => theme.spacing(1),
													textAlign: 'right',
													backgroundColor: (theme) => theme.palette.primary.light,
												}}
											>
												<Box sx={{ py: 2 }}>
													<Typography variant="body1" fontWeight={'bold'}>
														{userData?.firstName
															? userData['firstName'] + ' ' + userData?.lastName
															: userData?.email?.split('@')[0] || ''}
													</Typography>
													<Typography variant="body2" sx={{ maxWidth: '35em' }}>
														{v.send_message}
													</Typography>
												</Box>
												<Box sx={{ py: 2, pr: 2 }}>
													<Avatar
														src={
															(userData && userData?.photoURL) ||
															userData?.email?.toUpperCase() ||
															''
														}
														alt="receiver_profile"
														sx={{ ml: 2 }}
													/>
												</Box>
											</Box>
											<Typography
												variant="caption"
												textAlign={'right'}
												sx={{ display: 'block' }}
											>
												{format(
													new Date(v?.createdAt && v?.createdAt?.toDate()),
													'yyyy-MM-dd'
												) === format(new Date(), 'yyyy-MM-dd')
													? format(v?.createdAt?.toDate(), 'hh:mm a')
													: v?.createdAt?.toDate()?.toDateString()}
											</Typography>
										</Box>
									)}
								</Box>
							);
						})
				) : (
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'flex-end',
						}}
					>
						{!user.email ? (
							<Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
								<Box
									component={'span'}
									sx={{
										borderTop: (theme) => `2em solid ${theme.palette.primary.main}`,
									}}
									className="arrow-chat down-arrow"
								></Box>{' '}
								<span style={{ flex: 1, textAlign: 'center' }}>
									Select an employee to see your chat!
								</span>
							</Box>
						) : (
							<Typography variant="body1" sx={{ justifyContent: 'center' }}>
								No Chat found with{' '}
								{user?.firstName
									? user['firstName'] + ' ' + user?.lastName
									: user?.email?.split('@')[0] || ''}
								!
							</Typography>
						)}
					</Box>
				)}
			</Box>
			<form autoComplete="off" onSubmit={handlePing}>
				<Autocomplete
					options={users?.filter(({ id }) => id !== uid) || ['']}
					getOptionLabel={(option) => {
						if (option.hasOwnProperty('firstName')) {
							return option['firstName'] + ' ' + option?.lastName;
						}
						if (option.hasOwnProperty('email')) {
							return option['email']?.split('@')[0];
						}
						return option || ' ';
					}}
					isOptionEqualToValue={(option, value) =>
						!value
							? initialValue
							: option['firstName'] + ' ' + option?.lastName === value
					}
					id="format"
					size="small"
					value={
						user?.firstName
							? user['firstName'] + ' ' + user?.lastName
							: user?.email?.split('@')[0] || ''
					}
					onChange={(_, newValue) => handleChange(newValue)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Employees"
							variant="outlined"
							sx={{ minWidth: '250px' }}
							required
						/>
					)}
					sx={{ mr: 2 }}
				/>
				<TextField
					variant="outlined"
					placeholder="Send a message..."
					fullWidth
					size="small"
					required
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>
				<Button
					variant="contained"
					disabled={!user?.firstName || !user?.email?.split('@')[0] || loading}
					type={'submit'}
					color="primary"
					startIcon={loading && <CircularProgress size={15} />}
					sx={{
						minWidth: '130px',
						ml: 2,
						borderRadius: (theme) => theme.spacing(1),
						padding: '2px 5px',
					}}
				>
					Ping
				</Button>
			</form>
		</Container>
	);
}

export default Messages;
